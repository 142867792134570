import React, { useEffect } from "react";
import { Link } from "gatsby";
import { Layout } from "../components";
import $ from "jquery";
import ThankYouPageHand from "../components/thankYouPageHand";

const ContactThankYou = ({ pageContext: { slug, langKey } }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      $(".hand-container").addClass("animated");
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Layout
      seo={{
        title: "Kontakt potwierdzenie",
        href: slug,
        lang: langKey,
        noindex: true,
      }}
    >
      <div className="page_thanks">
        <div className="hand-container">
          <ThankYouPageHand />
        </div>
        <div className="text-container">
          <p className="desc">
            Dziękujemy za wypełnienie testu osobowości.
            <br /> <br />
            Do usłyszenia!
          </p>
          <p>
            <Link to="/" className="btn btn-box">
              <strong>strona główna</strong>
            </Link>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default ContactThankYou;
